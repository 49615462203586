import React from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { HomeIcon, ScheduleIcon, SeriesIcon, TrophyIcon } from '@shared/components/ctIcons'
import { allRoutes } from '@shared-submodule/sm-constants/allRoutes'
import CustomLink from '../customLink'
// import { Button } from 'react-bootstrap'
const MobileMenu = () => {
  const { t } = useTranslation()
  const router = useRouter()
  return (
    <ul className="mobileMenu d-flex d-md-none text-center text-uppercase font-semi mb-0">
      <li>
        <CustomLink href={allRoutes.home} prefetch={false}>
          <a className={`${router.pathname === allRoutes.home ? 'active' : ''}`}>
            <HomeIcon />
            {t('common:Home')}{' '}
          </a>
        </CustomLink>
      </li>
      <li>
        <CustomLink href={allRoutes.fixturesNew} prefetch={false}>
          <a className={`${router.pathname.includes(allRoutes.fixturesNew.replaceAll('/', '')) && 'active'}`}>
            <ScheduleIcon />
            {t('common:Fixtures')}
          </a>
        </CustomLink>
      </li>
      <li>
        <CustomLink href={allRoutes.cricketSeriesNew} prefetch={false}>
          <a className={`${router.pathname.includes(allRoutes.cricketSeriesNew.substring(1)) && 'active'}`}>
            <SeriesIcon />
            {t('common:Series')}
          </a>
        </CustomLink>
      </li>
      {/* <li>
        <CustomLink href={allRoutes.fantasyCricketTips} prefetch={false}>
          <a className={`${router.pathname.includes(allRoutes.fantasyCricketTips.replaceAll('/', '')) && 'active'}`}>
            <FantasyMenuIcon />
            FANTASY TIPS
            {t('common:Fantasy')}
          </a>
        </CustomLink>
      </li> */}
      <li>
        <CustomLink href={allRoutes.fantasyCricketTips} prefetch={false}>
          <a className={`${router.pathname.includes(allRoutes.fantasyCricketTips.replaceAll('/', '')) && 'active'}`}>
            <TrophyIcon />
            {t('common:Fantasy')}
          </a>
        </CustomLink>
      </li>
    </ul>
  )
}

export default MobileMenu

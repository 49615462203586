import { getCookie, setCookie } from '@shared-submodule/utils'

let token

/**
 * set user token NOTe: it will only work on client side
 * @param {string} t
 */
export const setToken = (t) => {
  token = t
  setCookie({
    cName: 'token',
    cValue: t,
    exDays: 1
  })
}
/**
 * Get current user token
 * @return {string}
 */
export const getToken = () => {
  const t = getCookie('token')
  return t || token
}

import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation UserLogin($input: userLogin) {
    userLogin(input: $input) {
      sMessage
      oData {
        sToken
      }
    }
  }
`

export const SOCIAL_SIGNIN = gql`
  mutation SocialSignUp($input: socialSignupInput!) {
  socialSignUp(input: $input) {
    oData {
      sToken
    }
    sMessage
  }
}
`

import { useMutation } from '@apollo/client'
import { SOCIAL_SIGNIN } from '@graphql/auth/login.mutation'
import { GOOGLE_CLIENT_ID } from '@shared-submodule/sm-constants'
import { setToken } from '@shared/libs/token'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useOnMouseAndScroll from '../useOnMouseAndScroll'

const useGoogleIdentify = (token) => {
  const url = 'https://accounts.google.com/gsi/client'
  const router = useRouter()
  const { isLoaded } = useOnMouseAndScroll()

  const [socialSignIn] = useMutation(SOCIAL_SIGNIN, {
    onCompleted: (data) => {
      if (data && data.socialSignUp !== null) {
        setToken(data.socialSignUp.oData.sToken)
        router.push(router.asPath)
        // router.back()
        // reset()
      }
    }
  })

  useEffect(() => {
    let timeoutId // declare a variable to store the timeout id
    if (!token && isLoaded) {
      const script = document.createElement('script')
      script.src = url
      script.async = true
      script.setAttribute('strategy', 'lazyOnload')
      document.head.appendChild(script)

      const initializeGoogleAccounts = () => {
        const { google } = window
        if (google) {
          google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            cancel_on_tap_outside: false,
            prompt_parent_id: 'g_id_onload',
            callback: async (response) => {
              await socialSignIn({
                variables: {
                  input: {
                    sSocialToken: response.credential,
                    sSocialType: 'G'
                  }
                }
              })
            }
          })

          google.accounts.id.prompt((notification) => {
            if (notification.isNotDisplayed()) {
              console.log('getNotDisplayedReason: ', notification.getNotDisplayedReason())
            } else if (notification.isSkippedMoment()) {
              console.log('isSkippedMoment: ', notification.getSkippedReason())
            } else if (notification.isDismissedMoment()) {
              console.log('isDismissedMoment: ', notification.getDismissedReason())
            }
          })
        } else {
          if (!token) {
            timeoutId = setTimeout(initializeGoogleAccounts, 10) // store the timeout id
          }
        }
      }

      initializeGoogleAccounts()
    } else {
      return () => {
        clearTimeout(timeoutId) // clear the timeout when the component unmounts
      }
    }
  }, [token, isLoaded])
}

export default useGoogleIdentify
